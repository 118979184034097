import { createRouter, createWebHashHistory } from 'vue-router'
import BlogHome from '@/components/BlogHome.vue'
import NewDetail from '@/components/NewDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: BlogHome
  },
  {
    path: '/articles/:id',
    name: 'NewDetail',
    component: NewDetail,
    props: true, // 允许路由参数作为组件的 props
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
