<template>
    <div>
        <!-- 文章信息 -->
        <div v-if="article">
            <h1>{{ article.title }}</h1>
            <p>作者: {{ article.author }}</p>
            <p>创建时间: {{ article.createdAt }}</p>
            <p>浏览量: {{ article.views }}</p>
            <p>点赞数: {{ article.likes }}</p>
            <p>正文: {{ article.content }}</p>
            <button @click="likeArticle">点赞</button>
        </div>

        <!-- 评论区 -->
        <div v-if="comments.length">
            <h2>评论区</h2>
            <ul>
                <li v-for="comment in filteredComments" :key="comment.id">
                    <p><strong>{{ comment.author }}:</strong> {{ comment.content }}</p>
                    <p>评论时间: {{ comment.createdAt }}</p>
                </li>
            </ul>
        </div>

        <!-- 添加评论 -->
        <div>
            <h3>添加评论</h3>
            <input v-model="newCommentAuthor" placeholder="您的名字" />
            <br />
            <textarea v-model="newCommentContent" placeholder="您的评论"></textarea>
            <br />
            <button @click="addComment">提交评论</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from 'axios';

const api = axios.create({
  baseURL: '/',
});

export default {
    name: "NewDetail",
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const article = ref(null);
        const comments = ref([]);
        const newCommentAuthor = ref("");
        const newCommentContent = ref("");

        // 获取文章信息
        const fetchArticle = async () => {
            api.get(`/articles/${props.id}`).then(response => {
                article.value = response.data.data;
            });
        };

        // 获取评论
        const fetchComments = async () => {
            api.get(`/comments/page?page=0&pageSize=100`).then(response => {
                comments.value = response.data.data.records;
            });
        };

        // 点赞文章
        const likeArticle = async () => {
            api.put(`/articles/like/${props.id}`).then(response => {
                if (response.data.code === 1) {
                    article.value.likes++;
                    alert("点赞成功！");
                }
            });
        };

        // 提交评论
        const addComment = async () => {
            const comment = {
                articleId: parseInt(props.id),
                author: newCommentAuthor.value,
                content: newCommentContent.value,
                createdAt: formatDate(new Date()), // 格式化日期
                status: "approved",
            };


            api.post("/comments/save", comment).then(response => {
                if (response.data.code === 1) {
                    comments.value.push(comment);
                    newCommentAuthor.value = "";
                    newCommentContent.value = "";
                }
            });
        };

        // 过滤当前文章的评论
        const filteredComments = computed(() => {
            let result = comments.value.filter((comment) => comment.articleId === props.id)
            return result;
        });

        onMounted(() => {
            fetchArticle(props.id);
            fetchComments(props.id);
        });

        const formatDate = (date) => {
            const pad = (num) => (num < 10 ? "0" + num : num);
            const year = date.getFullYear();
            const month = pad(date.getMonth() + 1);
            const day = pad(date.getDate());
            const hours = pad(date.getHours());
            const minutes = pad(date.getMinutes());
            const seconds = pad(date.getSeconds());

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        return {
            article,
            comments,
            newCommentAuthor,
            newCommentContent,
            likeArticle,
            addComment,
            filteredComments,
        };
    },
};
</script>