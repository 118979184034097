<template>
    <div class="blog-home">
        <h1>博客首页</h1>

        <!-- 加载状态 -->
        <div v-if="loading" class="loading">
            加载中...
        </div>

        <!-- 错误消息 -->
        <div v-if="error" class="error">
            {{ error }}
        </div>

        <!-- 文章列表 -->
        <div v-if="!loading && !error">
            <div v-for="article in articles" :key="article.id" class="article-card">
                <!-- 使用 <router-link> 进行导航 -->
                <h2>
                    <router-link :to="{ name: 'NewDetail', params: { id: article.id } }">
                        {{ article.title }}
                    </router-link>
                </h2>
                <p class="summary">{{ article.summary }}</p>
                <div class="meta">
                    <span>作者：{{ article.author }}</span>
                    <span>创建时间：{{ formatDate(article.createdAt) }}</span>
                    <span>浏览次数：{{ article.views }}</span>
                    <span>点赞数：{{ article.likes }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
    name: 'BlogHome',
    setup() {
        const articles = ref([]);
        const loading = ref(true);
        const error = ref(null);

        // 格式化日期
        const formatDate = (dateString) => {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        };

        // 获取文章列表
        const fetchArticles = async () => {
            try {
                const response = await axios.get('/articlelist/page', {
                    params: {
                        page: 0,
                        pageSize: 100,
                    },
                });
                // 假设响应数据直接是文章数组
                articles.value = response.data.data.records;
            } catch (err) {
                console.error(err);
                error.value = '无法加载文章列表，请稍后再试。';
            } finally {
                loading.value = false;
            }
        };

        onMounted(() => {
            fetchArticles();
        });

        return {
            articles,
            loading,
            error,
            formatDate,
        };
    },
};
</script>

<style scoped>
</style>